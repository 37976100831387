import React from 'react';
import { graphql } from 'gatsby';
import CraftPageBuilder from '~/components/craft/CraftPageBuilder';
import Seo from '~/components/common/Seo';
import { Query, Craft_Home } from '~/query';

type TemplateHomeProps = {
  data: Query;
};

/**
 * Template: Homepage
 *
 * Use this template in correspondence with a craft
 * section with the following properties:
 *
 * Type: single
 * Name: home
 * URI: keep the uri empty, because it's the home page
 */
export default function TemplateHome({ data }: TemplateHomeProps) {
  const { title, pageBuilder, seoTitle, seoDescription, seoCanonical } = (data?.craft
    ?.entry ?? {}) as Craft_Home;

  return (
    <>
      <Seo
        title={seoTitle ?? title}
        description={seoDescription ?? undefined}
        canonical={seoCanonical ?? undefined}
      />
      <CraftPageBuilder components={pageBuilder} />
    </>
  );
}

export const query = graphql`
  query QueryHome($id: [Int]!, $site: String!) {
    craft {
      entry(id: $id, site: $site) {
        id
        title
        ... on Craft_Home {
          pageBuilder {
            ...CraftPageBuilderQuery
          }

          # SEO
          seoTitle
          seoDescription
        }
      }
    }
  }
`;
